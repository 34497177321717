import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { User } from '../class/user';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private url = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json"
    })
  };
  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public getAdminList() {
    let data = {
      user_id: this.authService.getUser().id
    }
    return this.http.post(this.url + 'users/list', data);
  }
  public getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public addTutor(trainer, user_id) {

    const formData: FormData = new FormData();
    for ( var key in trainer ) {
      if(key=='categories'){
        if (typeof trainer.categories != 'undefined'){
         trainer.categories.forEach((category,index)=> {
          formData.append(`category_ids[${index}][id]`,category.id)
          formData.append(`category_ids[${index}][expiry_date]`,new Date(category.expiryDate).toISOString().split('T')[0])
          formData.append(`category_ids[${index}][limit]`,category.limit)
        });
      }

      }else{
        formData.append(key, trainer[key]);
      }


    }


    return this.http.post(this.url + 'users/add', formData);
  }
  public TrainerDetail(trainerId) {
    let data = {
      user_id: trainerId
    }
    return this.http.post(this.url + 'users/edit', data, this.httpOptions);
  }
  public updateTrainer(trainer, user_id) {
    let data = {
      user_id: user_id,
      name: trainer.name,
      email: trainer.email,
      role_code: trainer.roleCode,
    }
    if (typeof trainer.categories != 'undefined') {
      data['category_ids'] = trainer.categories.map(category => {
        if (typeof category.userCategoryId != 'undefined')
          return { user_category_id: category.userCategoryId, id: category.id, expiry_date: new Date(category.expiryDate).toISOString().split('T')[0], limit: category.limit }
        else
          return { user_category_id: "", id: category.id, expiry_date: new Date(category.expiryDate).toISOString().split('T')[0], limit: category.limit }
      });
    }

    //console.log(data);
    return this.http.post(this.url + 'users/update', data, this.httpOptions);
  }
  public getStudent(userId) {
    const data = {
      user_id: userId,
    }
    return this.http.post(this.url + 'students/list', data, this.httpOptions);
  }
  public addStudent(data, userId) {
    console.log("data",data)
    const formData: FormData = new FormData();
    formData.append('user_id', userId);

    data.categorySelected.forEach((category,index)=>{
      formData.append(`categories_id[${index}]`, category);
    })
    //console.log(data.fileToUpload.name);
    formData.append('file', data.fileToUpload, data.fileToUpload.name);
    new Response(formData).text().then(console.log)
    return this.http.post(this.url + 'students/add', formData);
  }
  public addSingleStudent(student, userId) {
    const data = {
      categories_id: student.category,
      user_id: userId,
      name: student.name,
      email: student.email,
      password: student.password,
      department_name:student.department,
      team_name:student.team_name,
      employee_id:student.employee_id,
    };
    return this.http.post(this.url + 'student_category/add', data, this.httpOptions);
  }
  public getTutorCategories(userId) {
    const data = {
      user_id: userId,
    }
    return this.http.post(this.url + 'trainer/category_list', data, this.httpOptions);
  }
  public validateTuotorEmail(email) {
    return this.http.post(this.url + 'users/email_validation', { email: email }, this.httpOptions);
  }
  public getStudentDetail(studentId) {
    return this.http.post(this.url + 'students/edit', { student_id: studentId }, this.httpOptions);
  }
  public updateStudent(student, studentId) {
    const data = {
      name: student.name,
      email: student.email,
      student_id: student.id,
      department_name: student.department,
      team_name: student.team_name,
      employee_id: student.employee_id,
      password:student.password,
      confirm_password:student.confirm
    }
    return this.http.post(this.url + 'students/update', data, this.httpOptions);
  }
  public updateusertype(user_id,flag) {
    console.log('if',user_id)
    const data = {
      user_id: user_id,
      flag: flag
    
    }
    return this.http.post('https://www.amldesk.com/increasemarks_api/index.php/' + 'update_user_type', data, this.httpOptions);
  }

  public getUser() {
    return this.http.get('https://www.amldesk.com/increasemarks_api/index.php/' + 'get_user_id');
  }
}
