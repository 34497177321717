import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Category, SubCategory } from '../class/category';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
   providedIn: 'root'
})
export class LessonService {
   private url = environment.apiUrl;
   public httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json',
         "Accept": "application/json"
      })
   };
   constructor(private http: HttpClient) {
   }

   public getLessonList() {
      return this.http.get(this.url + 'lesson/list');
   }

   public addLesson(lesson) {
      let data = {
         name: lesson.name,
         description: lesson.description,
         // category_ids: lesson.categories,
         // sub_category_ids: lesson.subCategories,
      }

      return this.http.post(this.url + 'lesson/add', data, this.httpOptions);
   }
   public LessonDetail(id) {
      let data = {
         lesson_id: id,
      }

      return this.http.post(this.url + 'lesson/edit', data, this.httpOptions);
   }
   public updateLesson(lesson) {
      let data = {
         lesson_id: lesson.id,
         name: lesson.name,
         description: lesson.description,
         //category_ids: lesson.categories,
         // sub_category_ids: lesson.subCategories
      }

      return this.http.post(this.url + 'lesson/update', data, this.httpOptions);
   }
   public changeLessonStatus(lesson_id, status) {
      let data = {
         lesson_id: lesson_id,
         status: status
      }

      return this.http.post(this.url + 'lesson/status', data, this.httpOptions);
   }
   public deleteLesson(lesson_id) {
      let data = {
         lesson_id: lesson_id,
      }

      return this.http.post(this.url + 'lesson/delete', data, this.httpOptions);
   }


}
